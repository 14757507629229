import { member , updateMember } from "../types";
export const MEMBERS_ERROR = 'MEMBERS_ERROR';
export const MEMBERS_SUCCESS = 'MEMBERS_SUCCESS';
export const MEMBERS_LOADING = 'MEMBERS_LOADING ';
export const DISPLAY_MEMBERS = 'DISPLAY_MEMBERS';
export const MEMBERS_CLEAR_ERROR = 'MEMBERS_CLEAR_ERROR';
export const MEMBERS_CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const MEMBER_LOADING = 'MEMBER_LOADING';
export const CREATE_MEMBER = 'CREATE_MEMBER';
export const MEMBER_SUCCESS = 'MEMBER_SUCCESS';
export const MEMBER_CLEAR_SUCCESS = "MEMBER_CLEAR_SUCCESS";
export const MEMBER_CLEAR_ERROR = 'MEMBER_CLEAR_ERROR';
export const MEMBER_ERROR = 'MEMBER_ERROR';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_ROLE = 'UPDATE_MEMBER_ROLE';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';

export interface MembersAction {
  type: typeof DISPLAY_MEMBERS,
  payload: {
    members: any
  }
}


export interface MembersSuccessAction {
  type: typeof MEMBERS_SUCCESS
}

export interface MembersClearSuccessAction {
  type: typeof MEMBERS_CLEAR_SUCCESS
}

export interface MembersLoadingAction {
  type: typeof MEMBERS_LOADING,
}

export interface MembersClearErrorAction {
  type: typeof MEMBERS_CLEAR_ERROR,
}
export interface MemberLoadingAction {
  type: typeof MEMBER_LOADING,
}



export interface CreateMemberAction {
  type: typeof CREATE_MEMBER,
  payload: {
      member: member
  }
}
export interface DeleteMemberAction {
  type: typeof DELETE_MEMBER,
  payload: {
      memberId: string
  }
}
export interface UpateMemberAction {
  type: typeof UPDATE_MEMBER_ROLE,
  payload: {
      role: updateMember
  }
}
export interface AddMemberSuccessAction {
  type: typeof MEMBER_SUCCESS,
  payload: {
      message: string
  }
}
export interface MemberErrorAction {
  type: typeof MEMBER_ERROR,
  payload: {
      message: string
  }
}
export interface MemberClearErrorAction {
  type: typeof MEMBER_CLEAR_ERROR,
}

export interface MemberClearSuccessAction {
  type: typeof MEMBER_CLEAR_SUCCESS,
}
export interface DeleteMemberSuccessAction {
  type: typeof DELETE_MEMBER_SUCCESS,
  payload: {
      message: string
  }
}
export interface UpdateMemberSuccessAction {
  type: typeof UPDATE_MEMBER_SUCCESS,
  payload: {
      message: string
  }
}

export type MembersActionTypes = MembersAction |  MembersClearErrorAction | MembersClearSuccessAction |  MembersSuccessAction | MembersLoadingAction|
CreateMemberAction |  DeleteMemberAction | MemberLoadingAction | AddMemberSuccessAction |
MemberErrorAction | MemberClearErrorAction | MemberClearSuccessAction | DeleteMemberSuccessAction | UpateMemberAction | UpdateMemberSuccessAction;