
import {
	DISPLAY_MEMBERS, MEMBERS_LOADING, MEMBERS_SUCCESS, MEMBERS_CLEAR_SUCCESS,
	MEMBERS_CLEAR_ERROR, MEMBER_ERROR, MEMBER_LOADING, MEMBER_CLEAR_ERROR, MEMBER_SUCCESS,
	MEMBER_CLEAR_SUCCESS, DELETE_MEMBER_SUCCESS, UPDATE_MEMBER_SUCCESS
} from "../types/Members";
import { MembersActionTypes } from "../types/Members";

interface DefaultStateI {
	members: any,
	error?: string,
	loading?: boolean,
	success?: boolean,
}
const membersReducerinitialState: DefaultStateI = {
	members: [],
	error: "",
	loading: false,
	success: false
};

const membersReducer = (state: DefaultStateI = membersReducerinitialState, action: MembersActionTypes): DefaultStateI => {

	switch (action.type) {
		case DISPLAY_MEMBERS: return { ...state, members: action.payload.members, loading: false }
		case MEMBERS_SUCCESS: return { ...state, success: true, loading: false }
		case MEMBERS_CLEAR_SUCCESS: return { ...state, success: false, loading: false }
		case MEMBERS_CLEAR_ERROR: return { ...state, error: "", loading: false }
		case MEMBERS_LOADING: return { ...state, loading: true }
		case MEMBER_LOADING: return { ...state, loading: true }
		case MEMBER_ERROR: return { ...state, error: action.payload.message, loading: false }
		case MEMBER_CLEAR_ERROR: return { ...state, error: "", loading: false }
		case MEMBER_SUCCESS: return { ...state, error: "", success: true, loading: false }
		case MEMBER_CLEAR_SUCCESS: return { ...state, success: false }
		case DELETE_MEMBER_SUCCESS: return {
			...state,
			success: true,
			loading:false,
			members: state.members.filter(
			  (member: any) => member.id !== action.payload.message
			),
		  };
		case UPDATE_MEMBER_SUCCESS: return { ...state, success: true, loading: false, error: "", }
	}
	return state;
};
export { membersReducer }