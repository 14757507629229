import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Loader from '../components/loader'

// import FolderPage from './folder'
// import TemplatesPage from './templates'

const LoginPage = lazy(() => import("./login"));
const HomePage = lazy(() => import("./home"));
const ChecklistsPage = lazy(() => import("./checklists"));
const MultipleChecklistPage = lazy(() => import("./multipleChecklist"));
const InboxPage = lazy(() => import("./inbox"));
const SingleChecklistPage = lazy(() => import("./singleChecklist"));
const SingleTemplatePage = lazy(() => import("./singleTemplate"));
const RegisterPage = lazy(() => import("./register"));
const EditTemplatePage = lazy(() => import("./editTemplate"));
const UserProfilePage = lazy(() => import("./user-profile"));
const ActivitiesPage = lazy(() => import("./activities"));
const AddMoreTemplates = lazy(() => import("./addScheduleChecklist"));
const OrganizationManagement = lazy(() => import("./organizations"));
const ManageOrganizationsGroups = lazy(() => import("./manageOrganizationGroup"));
const LandingPage = lazy(() => import("./landingPage"));
const ServicePage = lazy(() => import("./servicePage"));
const LibraryPage = lazy(() => import("./library"));
const MarketingPage = lazy(() => import("./marketingPage"));
const FolderSettingPage = lazy(() => import("./folderSetting"));
// const OpenFolderPage = lazy(() => import("./openFolder"));
const PrivacyPolicyPage = lazy(() => import("./privacyPolicy"));
const TermsServicePage = lazy(() => import("./termsService"));
const SupportPage = lazy(() => import("./support"));
const Createandgo = lazy(() => import("./loadandgo"))
const TemplatesPage = lazy(() => import("./templates"));
const SchedulePage = lazy(() => import("./viewScheduleList"));
const FolderPage = lazy(() => import("./folder"));
const CompleteProfile = lazy(() => import("../components/pages/register/complete-profile"));
const ForgotPasswordPage = lazy(() => import("./forgotPassword"));
const ResetPasswordPage = lazy(() => import("./resetPassword"));
const ConfirmEmailPage = lazy(() => import("./confirm-email"));
const TemplatePrint = lazy(() => import("./PrintTemplate"));
const AdminDashboard = lazy(() => import("./adminDashboard"));

const ShareLink = lazy(() => import("./shareLink"));
const RunLink = lazy(() => import("./runLink"));
const OrgInviteLink = lazy(() => import("./orgInviteLink"));
const LibraryTemplateView = lazy(() => import("./libraryTemplateView"));
const Team = lazy(() => import("./team"));
const TeamDetails= lazy(() => import("./teamMembarsDetails"));

export const Routes = () => {
	let userInfo = JSON.parse(localStorage.getItem("userInfo") as string);

	return (
		<Suspense fallback={<Loader />}>
			<Switch>

				{/* unauthenticated pages */}
				<Route exact path='/' component={LandingPage} />
				<Route exact path="/login" component={LoginPage} />
				<Route exact path="/register" component={RegisterPage} />
				<Route exact path="/home" component={HomePage} />
				<Route exact path='/complete-profile' component={CompleteProfile} />
				<Route exact path='/forgot-password' component={ForgotPasswordPage} />
				<Route exact path='/reset-password/:secret' component={ResetPasswordPage} />
				<Route exact path='/confirm-email/:secret' component={ConfirmEmailPage} />

				{/* authentication not required */}
				<Route exact path='/library' component={ServicePage} />
				<Route exact path='/library/category/:category' component={LibraryPage} />
				<Route exact path='/marketing' component={MarketingPage} />
				<Route exact path="/support" component={SupportPage} />
				<Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
				<Route exact path="/terms-and-conditions" component={TermsServicePage} />

				{/* user authenticated pages */}
				<Route exact path="/inbox" component={InboxPage} />
				<Route exact path="/checklists/add-multiple/:id" component={MultipleChecklistPage} />
				<Route exact path="/checklists/saved-views/:id" component={ChecklistsPage} />
				<Route exact path="/checklists/:id" component={SingleChecklistPage} />
				<Route exact path="/checklists/:id/task/:taskId?" component={SingleChecklistPage} />
				<Route exact path="/checklists/myview/:view_id" component={ChecklistsPage} />
				<Route exact path="/create-and-go" component={Createandgo} />
				{
					userInfo?.role !== "guest" && (<Route exact path="/templates/:id" component={SingleTemplatePage} />)
				}
				{
					userInfo?.role !== "guest" && (<Route exact path="/templates/edit/:id" component={EditTemplatePage} />)
				}
				{
					userInfo?.role !== "guest" && (<Route exact path="/templates" component={TemplatesPage} />)
				}
				{
					userInfo?.role !== "guest" && (<Route exact path="/template/:templateid/print" component={TemplatePrint} />)
				}
				{/* <Route exact path="/checklist/create/link/:link/:params?" component={TemplatesPage} /> */}
				<Route exact path="/activity" component={ActivitiesPage} />
				<Route exact path="/scheduled-templates" component={SchedulePage} />
				<Route exact path="/scheduled-templates/add-scheduled-templates" component={AddMoreTemplates} />
				<Route exact path='/user/manage' component={UserProfilePage} />
				<Route exact path='/organizations/manage' component={OrganizationManagement} />
				<Route exact path='/organizations/manage/group/:id' component={ManageOrganizationsGroups} />
				<Route exact path='/folderSetting' component={FolderSettingPage} />
				<Route exact path='/folder/:id' component={FolderPage} />
				{
					userInfo?.role !== "guest" && (<Route exact path="/templates/:info/view" component={ShareLink} />)
				}
				{
					userInfo?.role !== "guest" && (<Route exact path="/templates/:info/checklists/run" component={RunLink} />)
				}
				<Route exact path="/org/:info/join" component={OrgInviteLink} />
				<Route exact path="/checklist/:slug" component={LibraryTemplateView} />

				{/* admin authenticated pages */}
				<Route exact path='/admin-dashboard' component={AdminDashboard} />
				<Route exact path='/team' component={Team} />
				<Route exact path='/team-details' component={TeamDetails} />
			</Switch>
		</Suspense >
	);
}
